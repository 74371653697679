import React, { useState, useEffect } from 'react';
import { useParams } from '@reach/router';
import { LinkWithArrow } from 'src/components/shared';
import Icon from 'src/components/shared/Icon';
import { Breakpoints, Directions, useBreakpoints } from 'src/utils/breakpoint-utils';
import classNames from 'classnames';
require('src/styles/phase-header.scss');
require('src/styles/colors.scss');

interface PhasePageHeaderProps {
    children: React.ReactNode;
    title: string;
    subtitle: string;
    nextPhase: string;
    prevPhase: string;
    page: string;
}

const PhasePageHeader = ({
    children,
    title,
    subtitle,
    nextPhase,
    prevPhase,
    page,
}: PhasePageHeaderProps) => {
    const isTabletPortrait = useBreakpoints(Breakpoints.lg, Directions.down);
    const { ...params } = useParams();
    const phaseName = params.phaseName;
    const [iconName, setIconName] = useState<string>('');

    const linkStyle = 'display-inline-flex h6 bold';

    const loadInfo = () => {
        switch (phaseName) {
            case 'menstrual': {
                setIconName(
                    page == 'more-about'
                        ? 'pv-shape-1'
                        : page == 'foods'
                            ? 'pv-foods-filled'
                            : 'pv-meals-filled'
                );
                break;
            }
            case 'follicular': {
                setIconName(
                    page == 'more-about'
                        ? 'pv-shape-2'
                        : page == 'foods'
                            ? 'pv-foods-filled'
                            : 'pv-meals-filled'
                );
                break;
            }
            case 'ovulatory': {
                setIconName(
                    page == 'more-about'
                        ? 'pv-shape-3'
                        : page == 'foods'
                            ? 'pv-foods-filled'
                            : 'pv-meals-filled'
                );
                break;
            }
            case 'luteal': {
                setIconName(
                    page == 'more-about'
                        ? 'pv-shape-4'
                        : page == 'foods'
                            ? 'pv-foods-filled'
                            : 'pv-meals-filled'
                );
                break;
            }
        }
    };

    useEffect(() => {
        loadInfo();
    }, [phaseName]);

    return (
        <div className="phase-header-wrapper alignItems--flex-start">
            {!isTabletPortrait && (
                <LinkWithArrow
                    to={`/${page}/${prevPhase}`}
                    label={`${prevPhase}`}
                    className={classNames('link-left', linkStyle)}
                    back
                />
            )}
            <div className="phase-header-info-wrapper">
                <div
                    className={`phase-header-info-title display-row alignItems--center bgcolor-pv-${phaseName}`}
                >
                    <div className="display-column justifyContent-center padding-left--large">
                        <h1 className="bold upper margin--0">{title}</h1>
                        <h1 className="accent margin--0">{subtitle}</h1>
                    </div>
                    <div>
                        <Icon name={iconName} size={256} color="white" />
                    </div>
                </div>
                <div className="phase-header-info-desc padding--small display-column alignItems--center justifyContent-center">
                    {children}
                </div>
            </div>
            {!isTabletPortrait && (
                <LinkWithArrow
                    to={`/${page}/${nextPhase}`}
                    label={`${nextPhase}`}
                    className={classNames('link-right', linkStyle)}
                />
            )}
            {isTabletPortrait && (
                <div className="phase-header-links-mobile display-row alignItems--center margin-vertical--small">
                    <LinkWithArrow
                        to={`/${page}/${prevPhase}`}
                        label={`${prevPhase}`}
                        className={linkStyle}
                        back
                    />
                    <LinkWithArrow
                        to={`/${page}/${nextPhase}`}
                        label={`${nextPhase}`}
                        className={linkStyle}
                    />
                </div>
            )}
            <div className="phase-header-background"></div>
        </div>
    );
};

export default PhasePageHeader;
