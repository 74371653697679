import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react';
import classNames from 'classnames';
import * as Styles from 'src/styles/tabs.module.scss';

interface LabelProps {
    text: string;
    key: number;
    onClick: () => void;
}
interface TabsProps {
    children: Array<React.ReactNode>;
    whiteText?: boolean;
    centerTabs?: boolean;
}

const Tabs = ({ children, whiteText, centerTabs }: TabsProps) => {
    const [activeTab, setActivetab] = useState(children[0]?.props.label);

    const tabsTextStyle = whiteText ? Styles.tabsTextWhite : '';
    const tabsPositionStyle = centerTabs ? Styles.tabsCenter : '';

    const Label = ({ text, onClick }: LabelProps) => {
        return (
            <Menu.Item
                key={`tabMenuItem-${text}`}
                name={text}
                active={activeTab === text}
                onClick={onClick}
            />
        );
    };

    const renderTabContent = () => {
        const activeContent = children.find((item) => item?.props.label === activeTab);

        return activeContent?.props.children;
    };

    const renderLabels = () => {
        return children.map((item, index) => {
            const labelProps = {
                text: item?.props.label,
                key: index,
                onClick: () => handleClick(item),
            };
            return <Label {...labelProps} />;
        });
    };

    const handleClick = (item: React.ReactNode) => {
        const container = document.getElementById('tabs-component');
        if (container) {
            const itemID = Number(item.key) + 1;
            let containerItem = document.querySelector(`#tabs-component > a:nth-child(${itemID})`);
            let itemRect = containerItem?.getBoundingClientRect();

            if (itemRect?.left < 0) {
                container.scrollLeft -= itemRect?.width;
            }

            if (itemRect?.right > (window.innerWidth || document.documentElement.clientWidth)) {
                container.scrollLeft += itemRect?.width;
            }
        }
        setActivetab(item?.props.label);
    };
    return (
        <div
            className={classNames('display-column', Styles.tabs, tabsTextStyle, tabsPositionStyle)}
        >
            <Menu id="tabs-component" pointing secondary>
                {renderLabels()}
            </Menu>
            <div className={Styles.tabsContent}>{renderTabContent()}</div>
        </div>
    );
};

export default Tabs;
