import React from 'react';

interface TabsContentProps {
    className?: string;
    label: string;
    children: React.ReactNode;
}

const TabsContent = ({ className, children }: TabsContentProps) => (
    <div className={className}>{children}</div>
);

export default TabsContent;
