// extracted by mini-css-extract-plugin
export const phaseFoods = "phase-foods-layout-module--phase-foods--9oMTt";
export const phaseFoodsHeader = "phase-foods-layout-module--phase-foods-header--3aStI";
export const phaseFoodsIntro = "phase-foods-layout-module--phase-foods-intro--doqYj";
export const phaseFoodsPieTabs = "phase-foods-layout-module--phase-foods-pie-tabs--MQ_Lk";
export const phaseFoodsPie = "phase-foods-layout-module--phase-foods-pie--BoLjv";
export const phaseFoodsTabs = "phase-foods-layout-module--phase-foods-tabs--2_v0A";
export const phaseFoodsWeight = "phase-foods-layout-module--phase-foods-weight--3i-cO";
export const phaseFoodsWeightTitle = "phase-foods-layout-module--phase-foods-weight-title--2RmMH";
export const phaseFoodsWeightText = "phase-foods-layout-module--phase-foods-weight-text--2qi_a";
export const phaseFoodsDownload = "phase-foods-layout-module--phase-foods-download--PRP-e";
export const phaseFoodsLinks = "phase-foods-layout-module--phase-foods-links--2ZHad";
export const phaseFoodsLink = "phase-foods-layout-module--phase-foods-link--2xtaX";