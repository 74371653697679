import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from 'src/components/PrivateRoute';
import PhaseFoodsLayout from 'src/components/phase-and-function/phases/layouts/PhaseFoodsLayout';
import NotFoundPage from 'src/pages/404';

const PhaseFoods = () => (
    <Router>
        <PrivateRoute path="/foods/:phaseName" component={PhaseFoodsLayout} />
        <NotFoundPage default />
    </Router>
);

export default PhaseFoods;
