import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { useBreakpoints, Directions, Breakpoints } from 'src/utils/breakpoint-utils';
import PhasePageHeader from '../components/PhasePageHeader';
import { BorderBlockWithButton, Tooltip } from 'src/components/ui';
import { getDynamicContent } from 'src/utils/sdk-utils';
import { Loader, WidgetIcon } from 'src/components/shared';
import classNames from 'classnames';
import Tabs from 'src/components/ui/tabs/Tabs';
import TabsContent from 'src/components/ui/tabs/TabsContent';
import ReactMarkdown from 'react-markdown';
import { notifyBugsnagError } from 'src/utils/bugsnag';

import * as Styles from 'src/styles/phase-foods-layout.module.scss';
import { Link } from 'gatsby';
interface PhaseFoodsLayoutProps {
    phaseName: string;
}
interface FoodsTabs {
    label: string;
    content: Array<{ label: string; list: Array<string> }>;
}
interface Foods {
    additionalInformation: { title: string; text: string };
    downloadText: { title: string; text: string; fileUrl: string };
    foodsTab: Array<FoodsTabs>;
    imageUrl: string;
    intro: string;
    nextPhase: string;
    prevPhase: string;
    subtitle: string;
    title: string;
    tooltipText: string;
}

const initialState = {
    additionalInformation: { title: '', text: '' },
    downloadText: { title: '', text: '', fileUrl: '' },
    foodsTab: [],
    imageUrl: '',
    intro: '',
    nextPhase: '',
    prevPhase: '',
    subtitle: '',
    title: '',
    tooltipText: '',
};

const PhaseFoodsLayout = ({ phaseName }: PhaseFoodsLayoutProps) => {
    const [pageContent, setPageContent] = useState<Foods>(initialState);
    const [loading, setLoading] = useState(true);

    const isDesktop = useBreakpoints(Breakpoints.xxl, Directions.up);
    const isTabletPortraitOrBelow = useBreakpoints(Breakpoints.lg, Directions.down);
    const firstColumnWidth = isDesktop ? 8 : isTabletPortraitOrBelow ? 16 : 7;
    const secondColumnWidth = isDesktop ? 8 : isTabletPortraitOrBelow ? 16 : 9;

    const links = [
        {
            heading: 'Want to learn more?',
            icon: 'pv-mind',
            linkText: `${phaseName} DETAILS`,
            text: 'Dive into the details of the',
            url: `/more-about/${phaseName}`,
        },
        {
            heading: 'Ready to work out?',
            icon: 'pv-movement',
            linkText: `${phaseName} LIBRARY`,
            text: 'Choose a video from the',
            url: `/series/chapters/${phaseName}-phase`,
        },
        {
            heading: 'Need recipe inspiration?',
            icon: 'pv-meals',
            linkText: `${phaseName} MEALS`,
            text: 'Start cooking these',
            url: `/meals/${phaseName}`,
        },
    ];

    useEffect(() => {
        let isMounted = true;
        const getContent = async (phaseName: string) => {
            try {
                setLoading(true);
                const phaseCapitalize = phaseName.charAt(0).toUpperCase() + phaseName.slice(1);
                const content = await getDynamicContent(
                    `PhaseAndFunction.Foods.${phaseCapitalize}`
                );
                if (content && content.length > 0) {
                    if (isMounted) {
                        const pageContentResponse = content[0].value;
                        setPageContent(pageContentResponse);
                        setLoading(false);
                    }
                }
            } catch (err) {
                notifyBugsnagError(err);

                console.log({ err });
                return {};
            }
        };

        getContent(phaseName);

        return () => {
            isMounted = false;
        };
    }, [phaseName]);

    return loading ? (
        <div className="margin-top--large margin-bottom--large">
            <Loader />
        </div>
    ) : (
        <div className={classNames('display-column alignItems--center', Styles.phaseFoods)}>
            <div className={Styles.phaseFoodsHeader}>
                <PhasePageHeader
                    title={pageContent?.title}
                    subtitle={pageContent?.subtitle}
                    page={'foods'}
                    nextPhase={pageContent?.nextPhase}
                    prevPhase={pageContent?.prevPhase}
                >
                    <p className={classNames('p1', Styles.phaseFoodsIntro)}>{pageContent?.intro}</p>
                </PhasePageHeader>
            </div>
            <div className={Styles.phaseFoodsPieTabs}>
                <Grid centered>
                    <Grid.Column width={firstColumnWidth}>
                        <div className="justifyContent-center">
                            <img
                                src={pageContent?.imageUrl}
                                alt="pie chart"
                                className={Styles.phaseFoodsPie}
                            />
                        </div>
                        <div className="justifyContent-center margin-top--large">
                            <Tooltip icon="pv-question-mark-circle" title="How do I use this?">
                                <ReactMarkdown children={pageContent?.tooltipText} />
                            </Tooltip>
                        </div>
                    </Grid.Column>
                    <Grid.Column width={secondColumnWidth} className={Styles.phaseFoodsTabs}>
                        <Tabs>
                            {pageContent?.foodsTab.map((entry: FoodsTabs, i: number) => (
                                <TabsContent label={entry.label} key={i}>
                                    {entry.content.map((ingredients, j) => (
                                        <div className="margin-top--xlarge" key={j}>
                                            <h2 className="bold upper">{ingredients?.label}</h2>
                                            <div className="margin-top--small ">
                                                {ingredients.list.map(
                                                    (ingredient: string, k: number) => (
                                                        <p className="p1 margin--0" key={k}>
                                                            {ingredient}
                                                        </p>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </TabsContent>
                            ))}
                        </Tabs>
                    </Grid.Column>
                </Grid>
            </div>
            <div className={classNames('padding-vertical--large', Styles.phaseFoodsWeight)}>
                <Grid verticalAlign="middle">
                    <Grid.Column width={firstColumnWidth}>
                        <h2 className={classNames('accent', Styles.phaseFoodsWeightTitle)}>
                            {pageContent?.additionalInformation?.title}
                        </h2>
                    </Grid.Column>
                    <Grid.Column width={secondColumnWidth}>
                        <p className={classNames('p1', Styles.phaseFoodsWeightText)}>
                            {pageContent?.additionalInformation?.text}
                        </p>
                    </Grid.Column>
                </Grid>
            </div>
            <div className={Styles.phaseFoodsDownload}>
                <BorderBlockWithButton
                    alignment="top"
                    iconName="pv-file"
                    iconSize={48}
                    buttonProps={{
                        as: Link,
                        target: '_blank',
                        size: 'big',
                        to: pageContent?.downloadText?.fileUrl,
                        children: <>Download PDF</>,
                    }}
                >
                    <h2 className={'accent margin--0'}>{pageContent?.downloadText?.title}</h2>
                    <h5 className={'margin--0'}>{pageContent?.downloadText?.text}</h5>
                </BorderBlockWithButton>
            </div>
            <div className={Styles.phaseFoodsLinks}>
                {links.map((link, index) => (
                    <WidgetIcon info={link} key={index} className={Styles.phaseFoodsLink} />
                ))}
            </div>
        </div>
    );
};

export default PhaseFoodsLayout;
